<template>
  <div class="creek-broadcast-card" :class="['creek-broadcast-' + broadcast.id]">

    <div class="creek-broadcast-card-image" v-if="broadcast.show && broadcast.show.image">
      <img :src="broadcast.show.image.url" />
    </div>

    <div class="creek-broadcast-card-info">

      <div class="creek-broadcast-card-show-info" v-if="broadcast.show">
        <a
          class="creek-broadcast-card-show-title"
          @click="navigateTo('shows/'+broadcast.show.name)" 
          v-text="broadcastTitle"
        />
        <!--
        <div 
          class="creek-broadcast-card-summary" 
          v-text="broadcast.show.summary"
        ></div>
        -->
      </div>
      <div class="creek-broadcast-card-show-info" v-else>
        <div
          class="creek-broadcast-card-show-title"
          v-text="studioTitle"
        />
      </div>

      <div class="creek-broadcast-card-times">
        <span 
          class="creek-broadcast-card-start" 
          v-html="startDate"
        ></span>
        <span class="creek-divider">&mdash;</span>
        <span 
          class="creek-broadcast-card-end" 
          v-html="endDate"
        ></span>
      </div>

      <div 
        class="creek-broadcast-card-live"
        v-if="isCurrentBroadcast"
      >
        Live Now
      </div>

      <div class="creek-broadcast-card-control">
        <CreekPlayerControl 
          :broadcast="isCurrentBroadcast ? null : broadcast"
          :stream="streamIfCurrentBroadcast"
          :onlyIfPlayable="true"
        />
      </div>

      <!--
      <div class="creek-authors-container">
        <ul>
          <li v-for="author in broadcast.show.authors">
            <a 
              class="creek-author-link" 
              href="#" 
              :href="getProfileURL(author)" 
              v-text="author.display_name"
            ></a>
          </li>
        </ul>
      </div>
      -->

      <!--
      <div class="tags">
        <div class="tags-container">
          <ul>
            <li v-for="tag in show.tags">
              <a href="#" class="creek-category-link" v-text="tag.title"></a>
            </li>
          </ul>
        </div>
      </div>
      -->
    </div>

  </div>
</template>

<script type="text/javascript">

import CreekPlayerControl from '@creek-website-toolkit/components/controls/PlayerControl.vue'
import navigate from '@creek-website-toolkit/lib/navigate.js'
import time from '@creek-website-toolkit/lib/time.js'

export default {
  name: 'CreekBroadcastCard',
  components: {
    CreekPlayerControl
  },
  props: {
    broadcast: {
      type: Object
    },
  },
  data () { 
    return {
    } 
  },
  computed: {
    studioTitle(){
      let studioTitle = null
      if(this.$store.state.creek.studio.studio){
        studioTitle = this.$store.state.creek.studio.studio.title
      }
      return studioTitle
    },
    broadcastTitle(){
      
      // X('wooooop')
      let title = null

      title = this.broadcast.title ? this.broadcast.title :
        this.broadcast.show ? this.broadcast.show.title :
        this.studioTitle

      X(title)

      return title
    },
    startDate(){
      return time.format(this.broadcast.start)
    },
    endDate(){
      return time.format(this.broadcast.end)
    },
    isCurrentBroadcast(){
      const studio = this.$store.state.creek.studio
      return studio.current
        && studio.current.broadcast
        && studio.current.broadcast.id == this.broadcast.id
    },
    streamIfCurrentBroadcast(){
      if(!this.isCurrentBroadcast){
        return null
      }else{
        return this.$store.state.creek.studio.streamPrimary
      }
    }
  },
  methods: {
    getPage(){
      this.creek.$navigateTo('broadcast', broadcast.id)
    },
    navigateTo(path){
      navigate.to(path, this.$router)
    }
  },
  created() {

    X(this.broadcast)

  }
}

</script>